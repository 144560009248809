import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';

import styles from './buttons.module.scss';
import { Link } from 'react-router-dom';

export const IconButton = ({ icon, altText, handleClick }) => {
    return (
        <button onClick={handleClick} className={styles.iconButton}>
            <img src={icon} alt={altText} />
        </button>
    );
};

export const IconButtonCustom = ({ icon, altText, handleClick, className, ...props }) => {
    return (
        <button onClick={handleClick} className={`${styles.iconButton} ${className}`} {...props}>
            <img src={icon} alt={altText} />
        </button>
    );
};
export const RadioButton = ({ text, name, value, defaultChecked, isChecked, handleChange }) => {
    const onChange = (event) => {
        handleChange(event.target.value);
    };
    return (
        <div>
            <label className={styles.radioButtonLabel}>
                <input
                    value={value}
                    name={name}
                    type="radio"
                    className={styles.radioButton}
                    checked={isChecked}
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                />

                {text}
            </label>
        </div>
    );
};
export const TextButton = ({ text, border, handleClick }) => {
    return (
        <button
            onClick={handleClick}
            className={`${border ? styles.textButtonBorder : styles.textButton}`}
        >
            {text}
        </button>
    );
};
export const TextBannerLinkButton = ({ text, to, tierLevel }) => {
    return (
        <Link to={to} className={styles.textBannerLinkButton} state={{ tierLevel: tierLevel }}>
            <p className={styles.textBannerButtonText}>{text}</p>
            <MdNavigateNext className={styles.nextIcon} />
        </Link>
    );
};

export const TextBannerBackButton = ({ text, to }) => {
    return (
        <Link to={to} className={styles.textBannerBackLinkButton}>
            <MdNavigateBefore className={styles.nextIcon} />
            <p>{text}</p>
        </Link>
    );
};
export const TextButtonPrimary = ({ text, border, handleClick, disabled }) => {
    const onClick = () => {
        handleClick();
    };
    return (
        <button className={styles.textButtonPrimary} onClick={onClick} disabled={disabled}>
            {text}
        </button>
    );
};
