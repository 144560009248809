import 'utils/style/globals.css';
import styles from './footer.module.scss';

import {
    COPYRIGHT,
    PRIVACY_POLICY,
    PRIVACY_POLICY_URL,
    TERMS_OF_USE,
    TERMS_OF_USE_URL
} from 'utils/dictionary/footer';

// TODO: Need to include tooltips
const Links = (props) => {
    const bulletSymbol = ' \u2022 ';

    return (
        <>
            <a className={styles.footerLink} href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">{PRIVACY_POLICY}</a>
            <span className={styles.bulletContainer}>{bulletSymbol}</span>
            <a className={styles.footerLink}  href={TERMS_OF_USE_URL} target="_blank" rel="noopener noreferrer">{TERMS_OF_USE}</a>
        </>
    );
};

const Footer = () => {
    const barSymbol = '|';
    return (
        <footer className={styles.footerPositioning}>
            <div className={styles.backgroundContentFooter} />
            <div className={styles.footerContainer}>
                <footer className={styles.footer}>
                    <span>{COPYRIGHT}</span>
                    <div className={styles.barContainer}>{barSymbol}</div>
                    <Links />
                </footer>
            </div>
        </footer>
    );
};

export default Footer;
