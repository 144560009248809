// Styling and Layout
import styles from './contentPane.module.scss';

/*
    - Probably needs to be named differently.
    - Contains the formatting of each application setting for color/spacing.

 */
const ContentPane = (props) => {
    const { children } = props;

    return <div className={styles.contentPane}>{children}</div>;
};

export default ContentPane;
