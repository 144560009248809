import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import { API_ADMIN_ROLE } from 'utils/dictionary';

/**
 * ```js
 * const {
 *   // Auth state:
 *   error,
 *   isAuthenticated,
 *   isLoading,
 *   user,
 *   // Auth methods:
 *   getAccessTokenSilently,
 *   getAccessTokenWithPopup,
 *   getIdTokenClaims,
 *   loginWithRedirect,
 *   loginWithPopup,
 *   logout,
 * } = useAuth0<TUser>();
 * ```*/

// TODO: Instead of just returning the jwt, should we return the entire authState?
export const Auth0Jwt = () => {
    const authState = useAuth0();
    const [userJwt, setUserJwt] = useState('');

    useEffect(() => {
        const resolveIdTokenClaims = async () => {
            try {
                const token = await authState.getAccessTokenSilently();
                setUserJwt(token);
            } catch(err) {
                // Login required message by Auth0.
                if( err?.message !== "Login required") {
                    throw err;
                }
            }
        };

        if (authState != null) {
            resolveIdTokenClaims();
        }
    }, [authState]);

    return userJwt;
};

export const Auth0State = () => {
    let authState = useAuth0();
    return authState;
};

export const ParsedJwt = (jwt) => {
    return jwt_decode(jwt);
};

export const HasApiAdminRole = (token) => {
    if (token) {
        const parsedJwt = ParsedJwt(token);
        const roles = parsedJwt.hwRoles;

        const apiAdmin = roles.filter((x) => x.includes(API_ADMIN_ROLE));

        if (apiAdmin.length > 0) {
            return true;
        }
        return false;
    }

    return false;
};
