import {useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// Styling and Layout
import 'utils/style/globals.css';
import styles from './main.module.scss';

// Components
import Footer from 'components/Footer/footer';
import Header from 'components/Header/header';

import { Auth0Jwt, ParsedJwt } from 'utils/api/auth';

// Really at this point, this should probably be called 'Authenticated Main'.
const Main = () => {
    const [organizationAlias, setOrganizationAlias] = useState(undefined);

    const jwt = Auth0Jwt();

    // Get the organization alias from the jwt, once authn'ed.
    useEffect(()=> {
        const effect = async (accessToken) => {
            const parsedJwt = ParsedJwt(accessToken);

            // Just to be 'safe' before using it.
            if( parsedJwt?.tempOrganizationAlias) {
                setOrganizationAlias(parsedJwt.tempOrganizationAlias);
            }
        }

        if( jwt ) {
            effect(jwt);
        }
    }, [jwt]);

    return (
        <div className={styles.page}>
            <Header alias={organizationAlias} />
            <Outlet />
            <Footer />
        </div>
    );
};

export default Main;
