import * as utils from './http';

import * as envs from 'utils/env';

const baseUrl = `${envs.PROVISIONING_MANAGER_API_BASE_URL}`;
const credentialUrl = baseUrl + '/v1/credential';

export async function getCompassCredential(subscriptionId, show, accessToken) {
    return await utils.getResource(
        `${credentialUrl}/subscription/${subscriptionId}/compass/${show}`,
        accessToken
    );
}
