/* Generate the copyright string from 2022 to current year */
const copyrightYear = (props) => {
    var currentYear = new Date().getFullYear();
    return currentYear;
};

/* Copyright - 00a9 is the copyright symbol. */
export const COPYRIGHT = '\u00a9' + ' ' + copyrightYear() + ' Ignite Healthwise, LLC';

/* Privacy Policy URL */
export const PRIVACY_POLICY = 'Privacy Notice';
export const PRIVACY_POLICY_URL = 'https://www.healthwise.org/specialpages/legal/privacy.aspx';

/* Terms of Use Url */
export const TERMS_OF_USE = 'Terms of Use';
export const TERMS_OF_USE_URL = 'https://www.healthwise.org/specialpages/legal/terms.aspx';

export const SCREEN_READER_TOOLTIP_TEXT =
    'You are leaving this website for information purposes only.';
