/* Link and Route paths within the Settings UI application */

// Authentication and Authorization stuff
export const RELOAD_LOGIN_PATH = '/login/reload';
export const LOGIN_PATH = 'login';
export const LOGOUT_PATH = 'logout';
export const REDIRECT_PATH = 'implicit/callback';
export const SIGNIN_PATH = 'signin';
export const SIGNOUT_PATH = 'signout';

// Path of MyProfile in MyHealthwise
export const USER_PROFILE_PATH = '/profile';

// Healthcheck API
export const HEALTH_PATH = '/api/health';

// For testing use only
export const DEBUG_PATH = '/api/debug';

// General application routing
export const APPLICATION_PATH = 'application';
export const APPLICATIONS_PATH = 'applications';
export const BRANDING_PATH = 'branding';
export const HOME_PATH = '/';
