// This doesn't seem to work as I expect....
//import * as FooterDictionary from 'utils/dictionary/footer';

export const metadata = {
    title: 'Settings',
    description: 'Manage Configuration, Branding, and Settings for Healthwise Applications'
};

// The parameter to use to call versioned API endpoints that are versioned.
export const API_VERSION_QUERY_KEY = 'api-version';

// General Dictionary Items
export const PRODUCT_NAME = 'Settings';

export const API_ADMIN_ROLE = 'API Key Administrator';
