import React, { useContext, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Auth0Provider, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Auth0Jwt, HasApiAdminRole } from 'utils/api/auth';
import { useAuth0Data, PostLoginRedirector } from 'contexts/auth0Context';
import { UserInfoProvider } from 'contexts/userInfoContext';
import { TenantInfoProvider } from 'contexts/tenantInfoContext';


import { TenantInfoContext } from 'contexts/tenantInfoContext';

import {
    HEALTH_PATH,
    HOME_PATH,
    APPLICATION_PATH
} from 'utils/configuration/routes';

import Health from 'pages/health/health';
import Main from 'pages/main/main';
import Empty from 'pages/Empty/empty';
// Page Sections
import Application from 'pages/Application/application';
import Prelogin from 'pages/Prelogin';



/**
 *  AuthenticationGuard is the generic wrapper to permise users.
 *  Displays a default page while redirecting.
 *  Per Auth0 documentation.
 */
const AuthenticationGuard = (props) => {
    const { component } = props;
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <Prelogin />
    });
    return <Component />;
};

// TODO: Temporary bodge to suppress branding if HEM isn't available.
const Routing = () => {
    // Eventual valid results should be true, false, and undefined.
    const [compassApiAdmin, setCompassApiAdmin] = useState(undefined);
    const [compassApiAvailable, setCompassApiAvailable] = useState(undefined);

    const jwt = Auth0Jwt();
    const tenantInfo = useContext(TenantInfoContext);

    // Determine if the API is authorized
    useEffect(()=> {
        const effect = async (accessToken) => {
            const isApiAdmin = HasApiAdminRole(accessToken);
            setCompassApiAdmin(isApiAdmin);
        }

        if( jwt ) {
            effect(jwt);
        }
    },[jwt]);

    // Determine if the API is available
    useEffect(() => {
        const effect = async (tenantDetails) => {
            if( tenantDetails.applications ) {
                let filteredCompassApplications =
                    tenantDetails.applications?.filter(
                        (application) => application.sourceId === 'aCRHs000000br2XOAQ'
                    ) ?? [];

                if (filteredCompassApplications.length !== 0) {
                    setCompassApiAvailable(true);
                } else {
                    setCompassApiAvailable(false);
                }
            } else {
                // No applications what-so-ever came back, usually means the service call failed.
                setCompassApiAvailable(false);
            }
        };

        if (tenantInfo) {
            effect(tenantInfo);
        } 
    }, [tenantInfo]);

    return (
        <>
            {compassApiAdmin === undefined || compassApiAvailable  === undefined ? (<PendRouting />) : (<></>)}
            {compassApiAdmin && compassApiAvailable ? (<CompassRouting />) : (<CompassFreeRouting />)}
        </>
    );
};

// Routes for when the compassApiAdmin has not been resolved yet
const PendRouting = () => {
    return (
        <Routes>
            <Route exact path={HEALTH_PATH} element={<Health />} />
            <Route index element={<AuthenticationGuard component={Prelogin} />} />
            <Route path="*" element={<AuthenticationGuard component={Prelogin} />} />
        </Routes>
    ); 
}

// Thanks react-router-dom
const CompassFreeRouting = () => {
    return (
        <Routes>
            <Route exact path={HEALTH_PATH} element={<Health />} />
            <Route index element={<AuthenticationGuard component={Empty} />} />
            <Route path="*" element={<AuthenticationGuard component={Empty} />} />
        </Routes>
    );
};

// Thanks react-router-dom
const CompassRouting = () => {
    return (
        <Routes>
            <Route exact path={HEALTH_PATH} element={<Health />} />
            <Route path={HOME_PATH} element={<AuthenticationGuard component={Main} />}>
                <Route index element={<AuthenticationGuard component={Application} />} />
                <Route
                    path={APPLICATION_PATH}
                    element={<AuthenticationGuard component={Application} />}
                />
            </Route>
            <Route path="*" element={<AuthenticationGuard component={Main} />} />
        </Routes>
    );
};

const AuthorizedApp = () => {
    const { error, isLoading } = useAuth0();

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return <Prelogin />;
    }

    const queryClient = new QueryClient();

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <UserInfoProvider>
                    <TenantInfoProvider>
                        <Routing />
                    </TenantInfoProvider>
                </UserInfoProvider>
            </QueryClientProvider>
        </>
    );
};

const filterRoutes = ['unauthorized'];

export const Auth0App = () => {
    // TODO: Determine why we're using `useAuth0Data()` here but `useAuth0` in the `AuthorizedApp` method.
    const auth0Data = useAuth0Data();
    const [organizationAlias, setOrganizationAlias] = useState();

    if (!auth0Data || auth0Data.isLoading) return <Prelogin />;

    return (
        <Auth0Provider {...auth0Data.auth0Config}>
            <BrowserRouter basename={organizationAlias}>
                <PostLoginRedirector setOrgAlias={setOrganizationAlias} filterRoutes={filterRoutes}>
                    <AuthorizedApp />
                </PostLoginRedirector>
            </BrowserRouter>
        </Auth0Provider>
    );
};

export default Auth0App;
