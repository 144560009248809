import { useEffect, useState } from 'react';

import 'utils/style/globals.css';
import style from './application.module.scss';

import { Auth0Jwt } from 'utils/api/auth';
import { getApplicationSettings } from 'utils/api/settings';
import GroupElement from 'components/ContainerCard/groupElement';
import ContainerCard from 'components/ContainerCard';
import Warning from 'assets/icons/warning.svg';

import PageHeader from 'components/PageHeader';
import ContentPane from 'containers/ContentPane';
import { getCompassCredential } from 'utils/api/credential';
import { TextButton } from 'components/Buttons/buttons';
import CredentialModal from 'components/CredentialModal/credentialModal';

// Displays the Compass API key page

const ApplicationDisplay = () => {
    const [application, setApplication] = useState({});
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [credential, setCredential] = useState(null);
    const [openCredential, setOpenCredential] = useState(false);
    const [applicationSettingsLoaded, setApplicationSettingsLoaded] = useState(false);

    const jwt = Auth0Jwt();

    useEffect(() => {
        const fetchApplicationSettings = async () => {
            const result = await getApplicationSettings(jwt);

            const compass = result.applicationData.filter(
                (x) => x.sourceId === 'aCRHs000000br2XOAQ'
            )[0];

            setApplication(compass);
            setApplicationSettingsLoaded(true);
            setSubscriptionId(compass.subscriptionId);
        };
        if (!applicationSettingsLoaded && jwt) {
            fetchApplicationSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt, applicationSettingsLoaded]);

    useEffect(() => {
        const fetchCredential = async () => {
            const result = await getCompassCredential(subscriptionId, false, jwt);

            setCredential(result.data);
        };
        if (!credential && subscriptionId && jwt) {
            fetchCredential();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwt, subscriptionId]);

    return (
        <section className={style.main}>
            <ContainerCard title="Application Settings">
                <GroupElement
                    key={application.title}
                    title={application.title}
                    subtitle={application.subtitle}
                >
                    <ContentPane>
                        {credential?.display ? (
                            <>
                                <div className={style.column}>
                                    <div>
                                        <img
                                            src={Warning}
                                            alt="warning"
                                            className={style.warning}
                                        />
                                        <span className={`${style.infoBold} ${style.info}`}>
                                            Your API key is only displayed one time.
                                        </span>
                                    </div>
                                    <div className={`${style.info} ${style.infoOther}`}>
                                        Please be ready to copy and store your key in a secure
                                        location.
                                    </div>
                                </div>
                                <div className={style.column}>
                                    <div className={style.apiButton}>
                                        <TextButton
                                            text="View API Key"
                                            border={style.border}
                                            handleClick={() => setOpenCredential(true)}
                                        ></TextButton>
                                    </div>
                                </div>

                                <CredentialModal
                                    open={openCredential}
                                    setOpen={setOpenCredential}
                                    subscriptionId={subscriptionId}
                                    updateCredential={setCredential}
                                ></CredentialModal>
                            </>
                        ) : (
                            <>
                                <div className={style.column}>
                                    <div className={`${style.infoBold} ${style.info}`}>
                                        Your API key has already been viewed.
                                    </div>
                                    <div className={style.info}>
                                        Please contact your account manager if you need help.
                                    </div>
                                </div>
                            </>
                        )}
                    </ContentPane>
                </GroupElement>
            </ContainerCard>
        </section>
    );
};

const Application = () => {
    return (
        <>
            <PageHeader
                title="Applications"
                subtitle="Customize preferences for your applications."
            />
            <ApplicationDisplay />
        </>
    );
};

export default Application;
