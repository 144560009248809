import style from './spinner.module.scss';

/* Loader pulled from https://loading.io/css/ */

/**
 * Display a rotating spinner to indicate data processing or something.
 */
const Spinner = () => {
    return (
        <div className={style.spinner}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default Spinner;
