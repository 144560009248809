import { Link } from 'react-router-dom';

// Style Imports
import style from './userMenu.module.scss';

/**
 *  MenuItem is a standardized entry into a list for an item.
 */
const MenuItem = (props) => {
    const { icon, label, action } = props;

    return (
        <li className={style.menuItem} onClick={action} aria-hidden="true">
            <Link className={style.link} >
                <img className={style.icon} src={icon} alt="" />
                <span className={style.label}>{label}</span>
            </Link>
        </li>
    );
};

export default MenuItem;