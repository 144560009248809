import * as envs from 'utils/env';

// The Healthcheck Endpoint, leveraged by the deployment/monitoring process.

const Health = () => {
    let response = {
        commitHash: envs.COMMIT_HASH ?? '',
        buildNumber: envs.BUILD_NUMBER ?? '',
        buildId: envs.BUILD_ID ?? ''
    };

    return (
        <>
            <pre>{JSON.stringify(response, null, 2)}</pre>
        </>
    );
};

export default Health;
