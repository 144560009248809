import {useEffect, useState } from 'react';

// Styling and Layout
import 'utils/style/globals.css';
import style from './empty.module.scss';

import arrowBackIcon from 'assets/icons/arrow-back.svg';

// Components
import Footer from 'components/Footer/footer';
import Header from 'components/Header/header';

import { Auth0Jwt, ParsedJwt } from 'utils/api/auth';

/// This page just represents an 'error' when someone gets into the application without the proper privs.
import { MYHEALTHWISE_API_BASE_URL } from 'utils/env';

const Empty = () => {
    const [organizationAlias, setOrganizationAlias] = useState(undefined);

    const jwt = Auth0Jwt();

    // Get the organization alias from the jwt, once authn'ed.
    useEffect(()=> {
        const effect = async (accessToken) => {
            const parsedJwt = ParsedJwt(accessToken);

            // Just to be 'safe' before using it.
            if( parsedJwt?.tempOrganizationAlias) {
                setOrganizationAlias(parsedJwt.tempOrganizationAlias);
            }
        }

        if( jwt ) {
            effect(jwt);
        }
    }, [jwt]);

    // Build the URL and account for an undefined/empty alias.
    let myHealthwiseUrl = `${MYHEALTHWISE_API_BASE_URL}`;
    if( organizationAlias ) {
        myHealthwiseUrl = `${myHealthwiseUrl}/${organizationAlias}`;
    }

    return (
        <div className={style.page}>
            <Header alias={organizationAlias} />
            <section className={style.main}>
                <div className={style.row}>
                    Your current applications do not support use of the Settings application. If you believe this to be in error, please contact your Healthwise account manager.
                </div>
                <a className={style.link} href={myHealthwiseUrl}>
                    <div className={`${style.row} ${style.returnMessage}`}>
                        <div className={style.cell}>
                            <img className={style.arrowBackIcon} src={arrowBackIcon} alt="Return to MyHealthwise" />
                        </div>
                        <div className={style.cell}>Return to MyHealthwise</div>
                    </div>
                </a>
            </section>
            <Footer />
        </div>
    );
};

export default Empty;