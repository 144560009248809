import React from 'react';

import style from './valueWithClipboard.module.scss';
import ContentCopyIcon from 'assets/icons/content-copy.svg';
import { IconButtonCustom } from 'components/Buttons/buttons';
import { Tooltip } from 'react-tooltip';

const ValueWithClipboard = (props) => {
    const { title, value } = props;

    const inputLength = value?.length + 5;

    return (
        <div>
            {title ? <span className={style.title}>{title}</span> : <></>}

            <div>
                <input
                    type="text"
                    className={style.inputText}
                    value={value}
                    size={inputLength}
                    readOnly
                    onFocus={(e) => e.target.select()}
                />
                <span className={style.buttonContainer}>
                    <Tooltip
                        id="tooltip-click"
                        content="Copied!"
                        openOnClick={true}
                        place="top"
                        className={style.toolTip}
                    />
                    <IconButtonCustom
                        data-tooltip-id="tooltip-click"
                        className={style.button}
                        icon={ContentCopyIcon}
                        handleClick={() => {
                            navigator.clipboard.writeText(value);
                        }}
                    ></IconButtonCustom>
                </span>
            </div>
        </div>
    );
};

export default ValueWithClipboard;
