import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

import './index.css'; // change this to global.css

import { Auth0DataProvider } from 'contexts/auth0Context';
import { Auth0App } from 'components/Auth0App/auth0App';
import {
    AUTH0_CLIENT_ID,
    AUTH0_DOMAIN,
    AUTH0_AUDIENCE,
    AUTH0_SCOPE,
    MYHEALTHWISE_API_BASE_URL
} from 'utils/env';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Auth0DataProvider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENT_ID}
            audience={AUTH0_AUDIENCE}
            scope={AUTH0_SCOPE}
            myHealthwiseUrl={MYHEALTHWISE_API_BASE_URL}
        >
            <Auth0App />
        </Auth0DataProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
