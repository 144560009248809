/* eslint-disable */
// TODO: Need a11y information on elements

import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { TenantInfoContext } from 'contexts/tenantInfoContext';

import MenuItem from './menuItem';

// Icons
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import logoutIcon from 'assets/icons/logout.svg';
import appsIcon from 'assets/icons/apps.svg';
import userIcon from 'assets/icons/icon-user-reversed.svg';
import feedbackIcon from 'assets/icons/feedback.svg';
import toolsIcon from 'assets/icons/mdi_tools.svg';
import helpIcon from 'assets/icons/help.png';


// Style Imports
import style from './userMenu.module.scss';

// Import the Environment Variables into context.
import * as envs from 'utils/env';

// Import the needed strings/constants into context.
import * as dictionary from 'utils/dictionary/userMenu';

/*
 Questions:

 What's the difference between navigate and window.location.assign?
*/

export const ancestorHasId = (el, id) => {
    let parent = el;
    while (parent) {
        parent = parent.parentElement;
        if (parent && parent.id === id) {
            return parent;
        }
    }
    return undefined;
};

const UserMenu = (props) => {
    const { user } = props;
    let tenantInfo = useContext(TenantInfoContext) ?? {};
    let fullName = `${user.firstName} ${user.lastName}`;
    let email = `${user.email}`;
    let organizationAlias = `${user.organizationAlias}`;

    const myHealthwiseUrl = `${envs.MYHEALTHWISE_API_BASE_URL}/${organizationAlias}/applications${window.location.search}`;
    const myProfileUrl = `${envs.MYHEALTHWISE_API_BASE_URL}/${organizationAlias}/profile${window.location.search}`;

    const getHelpUrl = `${envs.HELP_URL}#${user.jwt}`;
    const contentFeedbackUrl = `${envs.MYHEALTHWISE_API_BASE_URL}/${organizationAlias}/feedback${window.location.search}`;
    const logServiceUrl = `${envs.LOG_SERVICE_URL}#${user.jwt}`;

    // Controls the visibility of the menu
    // TODO: Implement onclick or whatever is needed.
    const [visible, setVisible] = useState(false);
    const userMenuContainerId = 'userMenuContainer';

    //Navigate to the signout path
    const goToSignOut = () => {
        window.location.assign(`${envs.MYHEALTHWISE_API_BASE_URL}/${organizationAlias}/signout`);
    };

    // Navigate to MyHealthwise
    const goToApplications = () => {
        // `window.location.assign` seems to keep the user state (and jwt) instead of using the
        // `to` parameter of the Link from react-router.
        window.location.assign(myHealthwiseUrl);
    };

    // Navigate to the User's Profile
    const goToProfile = () => {
        window.location.assign(myProfileUrl);
    };

    // Navigate to the CSC
    const goToHelp = () => {
        window.open(getHelpUrl, '_blank');
    };

    const goToContentFeedback = () => {
        window.location.assign(contentFeedbackUrl);
    };

    const goToLogService = () => {
        window.open(logServiceUrl, '_blank');
    };
    
    const clickListener = (e) => {
        const isNotControlsParent = !ancestorHasId(e.target, userMenuContainerId);
        if (isNotControlsParent) {
            setVisible(false);
            removeClickHandler();
        }
    };

    const removeClickHandler = () => {
        window.removeEventListener('click', clickListener);
    };

    const addClickHandler = () => {
        window.addEventListener('click', clickListener);
    };

    const handleClick = (e) => {
        e.stopPropagation();
        setVisible(!visible);
        if (visible) {
            removeClickHandler();
        } else {
            addClickHandler();
        }
    };

    return (
        <div className={style.userMenuParent}>
            <div className={style.usermenu} onClick={handleClick}>
                <div className={style.imageWrapper}>
                    <img className={style.arrowDownIcon} onClick={handleClick} src={arrowDownIcon} alt="Expand User Menu" />
                </div>
            </div>
            <div id={userMenuContainerId} className={`${style.menuModal} ${visible ? '' : style.invisible}`}>
                <nav>
                    <ul className={style.menuSection}>
                        <li className={style.menuItem}>
                            <p className={style.name}>{fullName}</p>
                            <p className={style.email}>{email}</p>
                        </li>
                    </ul>
                    <hr />
                    <ul className={style.menuSectionMyHw}>
                        <div className={style.myHealthwiseSub}>
                            <MenuItem
                                icon={appsIcon}
                                label={dictionary.MYHEALTHWISE_BUTTON_TEXT}
                                action={goToApplications}
                            />
                        </div>
                    </ul>
                    <hr />
                    <ul className={style.menuSection}>
                        <MenuItem
                            icon={userIcon}
                            label={dictionary.MYPROFILE_BUTTON_TEXT}
                            action={goToProfile}
                        />
                        <MenuItem
                            icon={helpIcon}
                            label={dictionary.GETHELP_BUTTON_TEXT}
                            action={goToHelp}
                        />
                        {
                            user.logService &&
                            <MenuItem
                                icon={toolsIcon}
                                label={dictionary.LOG_SERVICE_BUTTON_TEXT}
                                action={goToLogService}
                            />
                        }
                        {
                            (user.contentFeedback && tenantInfo.isContentFeedbackEnabled) &&
                            <MenuItem
                                icon={feedbackIcon}
                                label={dictionary.CONTENT_FEEDBACK_BUTTON_TEXT}
                                action={goToContentFeedback}
                            />
                        }
                    </ul>
                    <hr />
                    <ul className={style.menuSection}>
                        <MenuItem
                            icon={logoutIcon}
                            label={dictionary.SIGNOUT_BUTTON_TEXT}
                            action={goToSignOut}
                        />
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default UserMenu;
