import * as utils from './http';

// Import the Environment Variables into context.
import * as envs from 'utils/env';

import * as dictionary from 'utils/dictionary';

// What this block of variables does is create the url to call for myprofile operations.
// e.g. https://manage.healthwise.net/myprofile?api-version=1.0
const controllerName = 'myprofile';
const baseUrl = `${envs.PROVISIONING_MANAGER_API_BASE_URL}/${controllerName}`;
const MYPROFILE_API_VERSION = '1.0';
const baseQueryString = `?${dictionary.API_VERSION_QUERY_KEY}=${MYPROFILE_API_VERSION}`;

// URLs for the user information
export const profileUrl = `${baseUrl}${baseQueryString}`;

/**
 * Lookup and retrieve the current user from User Management.
 * @param {string} accessToken A valid access token
 */
export async function getUserProfile(accessToken) {
    let response = await utils.getResource(`${profileUrl}`, accessToken);
    return response;
}
