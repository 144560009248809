// Styling and Layout
import 'utils/style/globals.css';
import styles from './prelogin.module.scss';

// Components
import LoadingCard from 'containers/LoadingCard';
import Footer from 'components/Footer/footer';
import Header from 'components/Header/header';

// The structure of Prelogin should match 1:1 with the Main component, just with the <Outlet>
// replaced with the LoadingCard component instead.
const Prelogin = () => {
    return (
        <div className={styles.page}>
            <Header />
            <main className={styles.main}>
                <LoadingCard message="Validating Credentials" />
            </main>
            <Footer />
        </div>
    );
};

export default Prelogin;
