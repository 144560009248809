import React, {useEffect, useState} from 'react';

// API and utility methods
import { Auth0Jwt } from 'utils/api/auth';
import { getTenantInformation } from 'utils/api/tenant';

const TenantInfoContext = React.createContext();

const TenantInfoProvider = ({ children }) => {
    const jwt = Auth0Jwt();
    const [tenantInfo, setTenantInfo] = useState(undefined);

    useEffect(() => {
        const resolveTenantInfo = async(accessToken) => {
            const response = await getTenantInformation(accessToken);

            if(response) {
                setTenantInfo(response);
            } 
        };

        if( jwt != null ) {
            resolveTenantInfo(jwt);
        }
    }, [jwt]);

    return <TenantInfoContext.Provider value={tenantInfo}>{children}</TenantInfoContext.Provider>;
};

export { TenantInfoContext, TenantInfoProvider };
