import { useQuery } from 'react-query';
//import { useErrorHandler } from 'react-error-boundary';

import { Auth0Jwt, ParsedJwt } from 'utils/api/auth';

import { getUserProfile } from 'utils/api/profile';

/**
 *  @typedef {Object} Query
 *  @property {Object} userProfile - The user's profile
 *  @property {boolean} isLoading - true if the query is still executing
 *  @property {Object} status - the status of the profile query
 */

/**
 * Retrieve the user's profile
 *
 * @return {Query} The user's profile and query status
 */
export function useProfile() {
    const accessToken = Auth0Jwt();
    const parsedToken = accessToken ? ParsedJwt(accessToken) : undefined;

    //const authState = Auth0State();
    //TODO: Reimplement
    // useErrorHandler(authState?.error);

    const { data, error, status } = useQuery(
        'userProfile',
        async () => {
            const data = await getUserProfile(accessToken);
            
            // Inject the organization alias and the current jwt into the user profile for use in 
            // the user menu. 
            if( parsedToken && data.data ) {
                data.data = {
                    ...data.data,
                    "organizationAlias": parsedToken.tempOrganizationAlias,
                    "jwt": accessToken
                }
            }

            return data;
        },
        { enabled: !!accessToken }
    );

    return { data, error, status };
}
