import env from '@beam-australia/react-env';

//Docker file injected environment variables
export const COMMIT_HASH = env('COMMIT_HASH');
export const BUILD_NUMBER = env('BUILD_NUMBER');
export const BUILD_ID = env('BUILD_ID');

export const MYHEALTHWISE_API_BASE_URL = env('MYHEALTHWISE_API_BASE_URL');
export const PROVISIONING_MANAGER_API_BASE_URL = env('PROVISIONING_MANAGER_API_BASE_URL');
export const API_BASE_URL = env('API_BASE_URL');

export const HELP_URL = env('HELP_URL');
export const LOG_SERVICE_URL = env('LOG_SERVICE_URL');

// Auth0 Specific configuration
export const AUTH0_DOMAIN = env('AUTH0_DOMAIN');
export const AUTH0_CLIENT_ID = env('AUTH0_CLIENT_ID');
export const AUTH0_AUDIENCE = env('AUTH0_AUDIENCE');
export const AUTH0_SCOPE = env('AUTH0_SCOPE');
