import style from './loadingCard.module.scss';

import Spinner from 'components/Spinner';

import HealthwiseLogo from 'assets/images/ignite-healthwise-logo.svg';

const LoadingCard = (props) => {
    const { message } = props;

    return (
        <div className={style.card}>
            <div className={style.contents}>
                <div>
                    <img src={HealthwiseLogo} alt="" />
                </div>
                <div>
                    <Spinner />
                </div>
                <div className={style.message}>{message}</div>
            </div>
        </div>
    );
};

export default LoadingCard;
