import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

// import {
//   HEALTH_PATH,
//   HOME_PATH,
//   LOGIN_PATH,
//   REDIRECT_PATH,
//   RELOAD_LOGIN_PATH,
//   SIGNIN_PATH
// } from 'utils/configuration/routes';

// const filterRoutes = [
//   HOME_PATH,
//   HEALTH_PATH,
//   RELOAD_LOGIN_PATH,
//   REDIRECT_PATH,
//   LOGIN_PATH,
//   SIGNIN_PATH
// ];

const Context = createContext();

export const Auth0DataProvider = (props) => {
    const { audience, clientId, domain, myHealthwiseUrl, children } = props;
    const { isAuthenticated } = useAuth0();

    const [auth0Data, setAuth0Data] = useState();
    const [organizationAlias, setOrganizationAlias] = useState();

    /// Retrieve the organizationId for a particular organization alias.
    const getOrgId = async (alias) => {
        // If there is an organization name in the url, fetch the organization id for it
        let requestHeaders = {
            Accept: 'application/json'
        };

        let request = new Request(`${myHealthwiseUrl}/organization?organizationName=${alias}`, {
            method: 'get',
            headers: new Headers(requestHeaders)
        });
        let response = await fetch(request);
        let data = await response.json();

        if (!data.organizationId) {
            // TODO - Throw some error here!
        }

        return data.organizationId;
    };

    const configureAuth0 = (organizationId) => {
        const authParams = {
            audience: audience,
            redirect_uri: window.location.origin,
        };

        if (organizationId) {
            authParams.organization = organizationId;
        }

        const providerConfig = {
            domain: domain,
            clientId: clientId,
            authorizationParams: authParams
        };
        return providerConfig;
    };

    /**
     * If there is NO organization alias on the route, we know we are ready to load the configuration for Auth0
     * and present the Enter Organization screen.
     */
    useEffect(() => {
        // Attempt to grab alias from url (https://baseurl/orgAlias/stuff)
        let alias = window.location.pathname.split('/').filter((o) => o.length); // Remove empty elements
        // TODO: Note: not sure what this is supposed to do...
        //.filter(r => !filterRoutes.includes(r))[0]; // Remove known application routes

        /**
         * If there isn't an organization alias in the route, check the query string parameter.
         * The querystring is used for a signout. This will ensure the user is not asked to
         * enter an Auth0 Organization Name after signing out.
         */
        if (!alias) {
            const queryParams = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop)
            });

            alias = queryParams.orgAlias;
        }

        if (!isAuthenticated) {
            // If the alias is in the url
            if (alias) {
                // Use an iffy to ensure we resolve all the appropriate async calls
                (async () => {
                    const organizationId = await getOrgId(alias);
                    const config = configureAuth0(organizationId);

                    setAuth0Data({
                        auth0Config: config,
                        organizationAlias: null, // Set after authentication happens
                        isLoading: false
                    });
                })();

                setOrganizationAlias(alias);
            } else {
                // If the alias is not in the url
                const config = configureAuth0();

                setAuth0Data({
                    auth0Config: config,
                    organizationAlias: null,
                    isLoading: false
                });
            }
        } else {
            auth0Data.organizationAlias = organizationAlias;
            setAuth0Data(auth0Data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return <Context.Provider value={auth0Data}>{children}</Context.Provider>;
};

export const useAuth0Data = () => useContext(Context);

export const PostLoginContext = createContext(null);

export const PostLoginRedirector = ({ setOrgAlias, filterRoutes, children }) => {
    const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [state, setState] = useState();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const setToken = async () => {
            const token = await getAccessTokenSilently();
            const decodedToken = jwt_decode(token);
            let baseRoute = decodedToken.tempOrganizationAlias;

            setOrgAlias(baseRoute);
            setState({
                organizationAlias: baseRoute
            });

            // If there is already a org alias on the route, set it to absolute pat
            const alias = window.location.pathname
                .split('/')
                .filter((o) => o.length)
                .filter((o) => !filterRoutes.includes(o))[0];

            if (!alias || baseRoute !== alias) {
                navigate({
                    pathname: baseRoute,
                    search: window.location.search
                });
            } else if (
                location.pathname === '/' &&
                location.pathname !== window.location.pathname
            ) {
                navigate({
                    pathname: window.location.pathname,
                    search: window.location.search
                });
            }
        };

        if (isAuthenticated) {
            setToken();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isLoading]);

    return <PostLoginContext.Provider value={state}>{children}</PostLoginContext.Provider>;
};

export const usePostLoginContext = () => useContext(PostLoginContext);
export default PostLoginContext;
