import * as utils from './http';

// Import the Environment Variables into context.
import * as envs from 'utils/env';

// Only used for local development.
import {
    fakedApplicationSettings,
    fakedApplicationSettingsCall
} from 'utils/dictionary/application.fakedata.js';

const baseUrl = envs.API_BASE_URL;
const settingUrl = baseUrl + '/api/settings';
const tenantSettingsUrl = settingUrl + '/tenant';
const contentFamiliesUrl = settingUrl + '/application';

/**
 * Shape of the Applications data object for pre-load rendering.
 *
 */
export const defaultSettings = {
    generalData: [],
    applicationData: []
};

/**
 * Retrieve all of the settings for the tenant the user is authenticated against.
 *
 */
export async function getTenantSettings(accessToken) {
    var results = structuredClone(defaultSettings);
    var tenantSettings = await utils.getResource(`${tenantSettingsUrl}`, accessToken);

    if (tenantSettings.error) {
        // Handle the non-200 response somehow.
        console.log(tenantSettings.error.status, tenantSettings.error.message);
    } else {
        var loadedGeneralData = convertToGeneralData(tenantSettings.data);
        results.generalData = loadedGeneralData;
    }

    return results;
}

/**
 * Retrieves the application specific settings, content families, optional features, etc for the
 * Tenant the user is authenticated against.
 */
export async function getApplicationSettings(accessToken) {
    var results = structuredClone(defaultSettings);
    var applicationSettings = await utils.getResource(`${contentFamiliesUrl}`, accessToken);

    if (applicationSettings.error) {
        // Handle the non-200 response somehow.
        console.log(applicationSettings.error.status, applicationSettings.error.message);
    } else {
        var loadedApplicationData = convertToApplicationData(applicationSettings.data);
        results.applicationData = loadedApplicationData;
    }

    return results;
}

export async function saveSettingsBlob(accessToken, settings) {
    await utils.patch(`${settingUrl}`, settings, accessToken);
}

// TODO: Remove This Function! (Once the Debug route is removed)
export async function debugAPICall() {
    console.error('Loading Fake Data with JWT');
    var loadedGeneralData = convertToGeneralData(fakedApplicationSettingsCall);
    var results = fakedApplicationSettings;

    results.generalData = loadedGeneralData;
    return results;
}

/**
 * convertToGeneralData will take the result from the call to the Setting Service and parse out the
 *  general application settings (type == 1) and return only those items in an object.
 */
const convertToGeneralData = (rawApplicationData) => {
    if (!rawApplicationData) {
        return [];
    }

    var generalData = rawApplicationData
        .filter(function (applicationItem) {
            return applicationItem.type === 1;
        })
        .map(function (generalItem) {
            // This looks a bit weird but it prevents the description or helper from being set to
            // `undefined`, as they are nullable fields.
            // optionValue, at this point, should be a boolean in string form.
            return {
                title: generalItem.name,
                ...(generalItem.description && { subtitle: generalItem.description }),
                ...(generalItem.helper && { helper: generalItem.helper }),
                value: generalItem.option.optionValue.toLowerCase() === 'true'
            };
        });

    // wrap the data into the expected container
    var wrappedResults = [
        {
            title: 'general',
            subtitle: 'common elements for the entire tenant',
            data: generalData
        }
    ];

    return wrappedResults;
};

/**
 * convertToApplicationData will take the result from the call to the Setting Service and parse out
 *  the non-general application settings (type != 1) and return only those items in an object.
 */
const convertToApplicationData = (rawApplicationData) => {
    if (!rawApplicationData) {
        return [];
    }

    var applicationData = rawApplicationData
        .filter(function (applicationItem) {
            return applicationItem.type !== 1;
        })
        .map(function (applicationItem) {
            // This looks a bit weird but it prevents the description or helper from being set to
            // `undefined`, as they are nullable fields.
            // optionValue, at this point, should be a boolean in string form.
            return {
                title: applicationItem.name,
                ...(applicationItem.description && { subtitle: applicationItem.description }),
                ...(applicationItem.helper && { helper: applicationItem.helper }),
                data: [], // This is where the application settings would be stored.
                features: convertFeatures(
                    applicationItem.enabledOptionalFeatures,
                    applicationItem.optionalFeatures
                ),
                contentsets: convertContentSets(
                    applicationItem.enabledAssets,
                    applicationItem.licensedAssets
                ),
                subscriptionId: applicationItem.subscriptionId,
                sourceId: applicationItem.sourceId
            };
        });

    return applicationData;
};

/**
 * convertContentSets combines the enabledAssets and licensedAssets arrays into a singular list
 *  for easier rendering to the UI.
 */
const convertContentSets = (enabledAssets, licensedAssets) => {
    if (!enabledAssets || !licensedAssets) return [];

    // walk through the licensed assets, building the contentsets object. If the item in
    //  licensedAssets exists in the enabledAssets, make sure to mark that value as enabled/true.
    var contentSets = licensedAssets.map(function (licensedAssetsItem) {
        return {
            id: licensedAssetsItem.assetId,
            applicationAssetId: licensedAssetsItem.applicationAssetId,
            name: licensedAssetsItem.name,
            ...(licensedAssetsItem.description && { description: licensedAssetsItem.description }),
            value:
                enabledAssets.filter(function (enabledAssetsItem) {
                    return (
                        enabledAssetsItem.applicationAssetId ===
                        licensedAssetsItem.applicationAssetId
                    );
                }).length !== 0
        };
    });

    return contentSets;
};

/**
 * convertFeatures combines the two objects into a singular list for easier rendering to the UI.
 */
const convertFeatures = (enabledFeatures, entitledFeatures) => {
    if (!enabledFeatures || !entitledFeatures) return [];

    var featureSets = entitledFeatures.map(function (entitledFeature) {
        return {
            id: entitledFeature.id,
            title: entitledFeature.name,
            ...(entitledFeature.description && { subtitle: entitledFeature.description }),
            ...(entitledFeature.helper && { helper: entitledFeature.helper }),
            value:
                enabledFeatures.filter(function (enabledFeature) {
                    return enabledFeature.featureId === entitledFeature.id;
                }).length !== 0
        };
    });

    return featureSets;
};
