import ProfileBlock from './ProfileBlock/profileBlock';
import Logo from 'assets/images/ignite-healthwise-logo-settings.svg';

import 'utils/style/globals.css';
import styles from './header.module.scss';

import { MYHEALTHWISE_API_BASE_URL } from 'utils/env';

const Header = props => {
    const {alias} = props;

    // Build the URL and account for an undefined/empty alias.
    let myHealthwiseUrl = `${MYHEALTHWISE_API_BASE_URL}`;
    if( alias ) {
        myHealthwiseUrl = `${myHealthwiseUrl}/${alias}`;
    }

    return (
        <div className={styles.headerBackground}>
            <header className={styles.hwheader}>
                <div className={styles.flexify}>
                    <a href={myHealthwiseUrl}>
                        <img src={Logo} width="325" height="44" alt="" style={{ marginTop: '12px' }} />
                    </a>
                    <div className={styles.rightSidebar}>
                        <ProfileBlock />
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;
