// Styling and Layout
import style from './groupElement.module.scss';

// Icons and Assets
import infoIcon from 'assets/icons/icon-info.svg';
import expandMore from 'assets/icons/expand-more.svg';
//import expandLess from 'assets/icons/expand-less.svg';

// TODO: When details 'opens', the icon needs to switch from expandMore to expandLess

/**
 * Renders a grouping of elements with an overarching group header with accordian-style control.
 *
 * @component
 * @param {string} title - The name of this group of Elements.
 * @param {string} subtitle - The quick explaination of what this group should contain.
 * @param {object} action - unused at this point in time (sorta)
 * @param {string} [helper] - The tooltip text to display, if provided, for this GroupElement.
 * @param {React.ReactNode} children - The Elements to render for this GroupElement
 */
// TODO: have an 'include summary' field so we can do the logic of general/applciations here?
const GroupElement = (props) => {
    const { title, subtitle, helper, children } = props;   

    return (
        <details className={style.details}>
            <summary>
                <div className={style.groupElement}>
                    <div className={style.column}>
                        <div className={style.groupTitle}>
                            {title}
                            {helper && <img className={style.infoIcon} src={infoIcon} alt="" />}
                        </div>
                        <div className={style.groupSubtitle}>{subtitle}</div>
                    </div>
                    <div className={style.column}>
                        <div className={style.action}>
                            <img className={style.groupExpand} src={expandMore} alt="" />
                        </div>
                    </div>
                </div>
            </summary>
            {children}
        </details>
    );
};

export default GroupElement;
