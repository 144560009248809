/*
 TODO:

 While this set of utility functions was ripped from the prov-tool, there are probably areas where 
 we can improve it. Such as 'postWith/WithoutContent'
*/

export async function getPaginatedResult(
    url,
    skip,
    top,
    accessToken,
    search = '',
    orderByField = '',
    orderByDirection = 'asc',
    query = {}
) {
    let resourceLocation = `${url}?skip=${skip}&top=${top}&search=${search}&orderByField=${orderByField}&orderByDirection=${orderByDirection}`;

    Object.keys(query).forEach(
        (key) => (resourceLocation = `${resourceLocation}&${key}=${query[key]}`)
    );

    const { response, json, error } = await get(resourceLocation, accessToken);

    const pagination = json?.meta?.pagination || {
        returned: 0,
        available: 0,
        skip: 0,
        top: 10
    };

    pagination.page = pagination.skip / pagination.top + 1;

    const nextPage =
        response?.status === 200 && pagination.available > top + skip
            ? async () => {
                  return await getPaginatedResult(
                      url,
                      skip + pagination.returned,
                      top,
                      accessToken
                  );
              }
            : null;

    return {
        response,
        data: response?.status === 200 ? json.data : null,
        pagination: pagination,
        nextPage: nextPage,
        error: response?.status === 200 ? null : error || json.data
    };
}

export async function getResource(url, accessToken) {
    const { response, json, error } = await get(url, accessToken);

    return {
        response,
        data: response?.status === 200 ? json : null,
        etag: response?.headers?.get('etag'),
        error: response?.status === 200 ? null : error || json
    };
}

export async function createResource(url, accessToken, body) {
    const { response, json, error } = await post(url, body, accessToken);

    return {
        response,
        data: response?.status === 201 ? json : null,
        error: response?.status === 201 ? null : error || json
    };
}

export async function postWithContent(url, accessToken, body) {
    const { response, json, error } = await post(url, body, accessToken);

    return {
        response,
        data: response?.status === 200 ? json : null,
        error: response?.status === 200 ? null : error || json
    };
}

export async function postWithoutContent(url, accessToken, body) {
    const { response, json, error } = await post(url, body, accessToken);

    return {
        response,
        data: response?.status === 204 ? json : null,
        error: response?.status === 204 ? null : error
    };
}

export async function updateResource(url, accessToken, body, etag) {
    const { response, json, error } = await patch(url, body, etag, accessToken);

    return {
        response,
        data: response?.status === 200 ? json : null,
        error: response?.status === 200 ? null : error || json,
        etag: response?.headers?.get('etag')
    };
}

export async function putResource(url, accessToken, body, etag) {
    const { response, json, error } = await put(url, body, accessToken, etag);

    return {
        response,
        data: response?.status === 200 ? json : null,
        error: response?.status === 200 ? null : error || json
    };
}

export async function deleteResource(url, accessToken, etag) {
    const { response, json, error } = await _delete(url, etag, accessToken);

    return {
        response,
        data: response?.status === 200 ? json : null,
        error: response?.status === 200 ? null : error || json
    };
}

/*
 * Make the http request using fetch(...).
 * If the fetch response in not ok (200-299),
 * the error is packed with the status and message.
 *
 * return values:
 *   response: the full response object
 *   json: the parsed json response
 *   error: any logical or network errors encountered.
 * Any value can be null or undefined
 */
export async function http(request) {
    let response = null;
    let json = null;
    let error = null;

    try {
        response = await fetch(request);

        if (response.ok) {
            if (response.status !== 204) {
                json = await response.json();
            }
        } else {
            let details;

            try {
                details = await response.json();
            } catch {
                /* Doing nothing on purpose */
            }

            // Pack the error message with the status and text
            error = {
                status: response.status,
                message: response.statusText,
                details
            };
        }
    } catch (e) {
        // This error is a network error.
        // TODO: Need to somehow populate the response object with a failure.
        // Otherwise we have to keep doing response?.ok to check.
        error = e;
    }

    return { response, json, error };
}

export async function get(url, accessToken, headers) {
    var requestHeaders = {
        Accept: 'application/json',
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        ...headers
    };

    return await http(new Request(url, { method: 'GET', headers: new Headers(requestHeaders) }));
}

export async function post(url, body, accessToken, headers) {
    var requestHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        ...headers
    };

    return await http(
        new Request(url, {
            method: 'POST',
            headers: new Headers(requestHeaders),
            body: body ? JSON.stringify(body) : null
        })
    );
}

export async function patch(url, body, accessToken, headers/*, etag*/) {
    var requestHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        //...(etag && { 'If-Match': `${etag}` }),
        ...headers
    };

    return await http(
        new Request(url, {
            method: 'PATCH',
            headers: new Headers(requestHeaders),
            body: JSON.stringify(body)
        })
    );
}

// NOTE: Watch the order of the parameters, they've been switched
//       in comparison to the other methods.
// SEE: https://healthwise.atlassian.net/browse/P40-488
export async function put(url, body, accessToken, etag, headers) {
    var requestHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        ...(etag && { 'If-Match': `${etag}` }),
        ...headers
    };

    return await http(
        new Request(url, {
            method: 'PUT',
            headers: new Headers(requestHeaders),
            body: JSON.stringify(body)
        })
    );
}

export async function _delete(url, etag, accessToken, headers) {
    var requestHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        ...(etag && { 'If-Match': `${etag}` }),
        ...headers
    };

    return await http(
        new Request(url, {
            method: 'DELETE',
            headers: new Headers(requestHeaders)
        })
    );
}
