import 'utils/style/globals.css';
import styles from './pageHeader.module.scss';

/**
 * Generate the wide subheader for the page that is being rendered.
 */
const PageHeader = (props) => {
    const { title, subtitle } = props;

    return (
        <div className={styles.fullwidth}>
            <div className={styles.container}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{subtitle}</div>
            </div>
        </div>
    );
};

export default PageHeader;
