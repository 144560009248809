import React, { useEffect, useState } from 'react';
import { Auth0Jwt } from 'utils/api/auth';
import ValueWithClipboard from 'components/ValueWithClipboard/valueWithClipboard';
import { getCompassCredential } from 'utils/api/credential';
import { IconButtonCustom } from 'components/Buttons/buttons';
import CloseIcon from 'assets/icons/close.svg';
import Warning from 'assets/icons/warning.svg';
import style from './credentialModal.module.scss';
import Modal from 'react-modal';

const CredentialModal = ({ open, setOpen, subscriptionId, updateCredential }) => {
    const accessToken = Auth0Jwt();
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [credential, setCredential] = useState({});

    useEffect(() => {
        const response = async () => {
            const { data, response } = await getCompassCredential(
                subscriptionId,
                true,
                accessToken
            );
            if (response.ok) {
                setClientId(data.clientId);
                setClientSecret(data.clientSecret);
                setCredential(data);
            }
        };
        if (open) {
            response();
        }
    }, [open, accessToken, subscriptionId]);

    const handleClose = () => {
        setOpen(false);
        updateCredential(credential);
    };

    return (
        <Modal
            isOpen={open}
            className={style.modal}
            overlayClassName={style.overlay}
            appElement={document.getElementById('root')}
        >
            <div className={style.titleContainer}>
                <span className={style.title}>My API Key</span>
                <IconButtonCustom
                    className={style.closeIcon}
                    icon={CloseIcon}
                    handleClick={handleClose}
                ></IconButtonCustom>
            </div>
            <div className={style.banner}>
                <img src={Warning} alt="warning" className={style.warning} />
                <span className={`${style.infoBold} ${style.info}`}>
                    Your API key is only displayed one time.
                </span>
                <div className={`${style.info} ${style.infoOther}`}>
                    Please copy and store your key in a secure location.
                </div>
            </div>
            <div className={style.content}>
                <ValueWithClipboard value={clientId} title="Client ID" />
                <ValueWithClipboard value={clientSecret} title="Secret" />
            </div>
        </Modal>
    );
};

export default CredentialModal;
