import * as envs from 'utils/env';
import * as utils from './http';

const baseUrl = envs.API_BASE_URL;
const tenantUrl = baseUrl + '/api/tenant';
const tenantInfoUrl = tenantUrl + '/info';

/**
 * Retrieve all of the settings for the tenant the user is authenticated against.
 *
 */
export async function getTenantInformation(accessToken) {
    var results;

    if( !accessToken ){
        return undefined;
    }

    var tenantInfo = await utils.getResource(`${tenantInfoUrl}`, accessToken);

    if( tenantInfo.error ) {
        results = {};
    } else {
        results = tenantInfo.data;
    }

    return results;
}