import { useContext } from 'react';

import UserMenu from 'components/UserMenu';

// Contexts
import { UserInfoContext } from 'contexts/userInfoContext';

import userIcon from 'assets/icons/icon-user.svg';
//import arrowDown from 'assets/icons/arrow-down.svg';

import 'utils/style/globals.css';
import style from './profileBlock.module.scss';

import {PRODUCT_NAME} from 'utils/dictionary';
/*
 * ProfileBlock
 *
 * This is the UI element that holds the Profile, user menu, etc in the header.
 */
const ProfileBlock = () => {
    let userInfo = useContext(UserInfoContext) ?? {};

    if (userInfo?.status !== 'success') {
        return <div className={style.profileBlock}></div>;
    }

    return (
        <div className={style.profileBlock}>
            <div className={style.imageBlockItem}>
                <img className={style.accountIcon} src={userIcon} alt="" />
            </div>
            <div className={style.profileBlockItem}>
                <div className={style.userBlock}>
                    <span className={style.username}>{userInfo.data?.data?.firstName}</span>
                    <div className={style.usermenu}>
                        <UserMenu user={userInfo.data?.data} />
                    </div>
                </div>
                <div className={style.userBlock}>
                    <span className={style.applicationName}>{PRODUCT_NAME}</span>
                </div>
            </div>
        </div>
    );
};

export default ProfileBlock;
