// Styling and Layout
import style from './containerCard.module.scss';

/**
 * Render the title or header of the container card.
 *
 * @component
 * @param {string} title The title for this container card
 */
const Title = (props) => {
    const { title } = props;

    return (
        <div className={style.header}>
            <div className={style.column}>
                <h3 className={style.title}>{title}</h3>
            </div>
        </div>
    );
};

/**
 * Overall structure and layout of the component.
 */
const ContainerCard = (props) => {
    const { title } = props;

    return (
        <div className={style.card}>
            <Title title={title} />
            {props.children}
        </div>
    );
};

export default ContainerCard;
